import React, { useEffect, useState } from 'react'
import { FallingLinesLoader } from '../../../components/spinners/Spinner'
import Table from '../../../components/tables/table'
import Breadcrumb from '../../../components/Breadcrumb'
import { Link, useNavigate } from 'react-router-dom'
import { getAllowedActions, checkPermissionsAndRedirect } from "../../../helper/commonHelper";
import ReportServices from '../../../ApiServices/ReportServices'
import { RegionReport_columns } from '../../../components/tables/tableheader'
import moment from 'moment';
import { convertToAnotherTimezone } from '../../../helper/commonHelper';
import { utils, writeFile } from 'xlsx';
import { toast } from 'react-toast';
import MasterServices from '../../../ApiServices/MasterServices'
import Select from 'react-select'
import makeAnimated from "react-select/animated";

const data = [
  { OrderType: 'P', RegionCode: 0, CopyDel: 'AM', CopyCour: '', Count: 18, Copies: 18 },
  { OrderType: 'P', RegionCode: 1, CopyDel: 'COU', CopyCour: 'DTC', Count: 41, Copies: 41 },
  { OrderType: 'P', RegionCode: 1, CopyDel: 'CPS', CopyCour: '', Count: 192, Copies: 192 },
  { OrderType: 'P', RegionCode: 1, CopyDel: 'HWK', CopyCour: 'AK', Count: 171, Copies: 171 },
  { OrderType: 'P', RegionCode: 1, CopyDel: 'HWK', CopyCour: 'AKS', Count: 282, Copies: 282 },
  { OrderType: 'P', RegionCode: 1, CopyDel: 'HWK', CopyCour: 'ASH', Count: 43, Copies: 48 },
  { OrderType: 'P', RegionCode: 1, CopyDel: 'HWK', CopyCour: 'SKS', Count: 59, Copies: 59 },
  { OrderType: 'P', RegionCode: 1, CopyDel: 'HWK', CopyCour: 'SMH', Count: 86, Copies: 122 },
  { OrderType: 'P', RegionCode: 2, CopyDel: 'COU', CopyCour: 'DTC', Count: 217, Copies: 217 },
  { OrderType: 'P', RegionCode: 2, CopyDel: 'COU', CopyCour: 'FCS', Count: 68, Copies: 68 },
  { OrderType: 'P', RegionCode: 2, CopyDel: 'COU', CopyCour: 'MSC', Count: 93, Copies: 96 },
  { OrderType: 'P', RegionCode: 2, CopyDel: 'COU', CopyCour: 'SLR', Count: 9, Copies: 9 },
  { OrderType: 'P', RegionCode: 2, CopyDel: 'CPS', CopyCour: '', Count: 1165, Copies: 1165 },
  { OrderType: 'P', RegionCode: 2, CopyDel: 'HWK', CopyCour: 'DPS', Count: 48, Copies: 48 },
  { OrderType: 'P', RegionCode: 2, CopyDel: 'LUK', CopyCour: '', Count: 69, Copies: 69 },
  { OrderType: 'P', RegionCode: 3, CopyDel: 'COU', CopyCour: 'RC', Count: 57, Copies: 57 },
  { OrderType: 'P', RegionCode: 3, CopyDel: 'COU', CopyCour: 'STR', Count: 106, Copies: 106 },
  { OrderType: 'P', RegionCode: 3, CopyDel: 'COU', CopyCour: 'VC', Count: 220, Copies: 220 },
  { OrderType: 'P', RegionCode: 3, CopyDel: 'CPS', CopyCour: '', Count: 26, Copies: 26 },
  { OrderType: 'P', RegionCode: 4, CopyDel: 'COU', CopyCour: 'NAG', Count: 46, Copies: 46 },
  { OrderType: 'P', RegionCode: 4, CopyDel: 'COU', CopyCour: 'RC', Count: 54, Copies: 54 },
  { OrderType: 'P', RegionCode: 4, CopyDel: 'COU', CopyCour: 'STR', Count: 49, Copies: 49 },
  { OrderType: 'P', RegionCode: 4, CopyDel: 'CPS', CopyCour: '', Count: 432, Copies: 432 },
  { OrderType: 'P', RegionCode: 5, CopyDel: 'COU', CopyCour: 'MAD', Count: 107, Copies: 107 }
];

const RegionReport = () => {
  const pages = [{ title: "Region Report", href: `/region-report`, module_id: 26 }];
  const [isLoading, setIsLoading] = useState(false)
  const [regionReportList, setRegionReportList] = useState(data)
  const [getAllMagazine, setGetAllMagazine] = useState([])
  const [getAllMagazinesIssue, setGetAllMagazinesIssue] = useState([]);
  const navigate = useNavigate();
  const [allowedActions, setAllowedActions] = useState([]);
  const animatedComponents = makeAnimated();
  useEffect(() => {
    const permissions = JSON.parse(localStorage.getItem("user_permissions"));
    if (permissions && window.location.pathname) {
      const checkPermissionsAndRedirectFunc = checkPermissionsAndRedirect(window.location.pathname, permissions, navigate);
      const actions = getAllowedActions(permissions, window.location.pathname);
      setAllowedActions(actions);
    }
  }, []);

  useEffect(() => {
    document.title = "Outlook | Region Report";
    getRegionReport();
    getMagazines();
  }, [])
  // Filter Values Start
  let filterInitialValues = {
    filterIssueDate: "",
    filterMagazineId: { label: "", value: "" },
    filterMagazineIssueId: { label: "", value: "" },
    filterMagazineName: "",
  };
  let [filterRegion, setFilterRegion] = useState(filterInitialValues);
  const [filterFlag, setFilterFlag] = useState(true);
  const [isSearched, setIsSearched] = useState(false);
  function checkObjectValues(obj) {
    for (const key in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, key)) {
        if (typeof obj[key] === "object") {
          if (checkObjectValues(obj[key])) {
            return true;
          }
        } else if (obj[key] !== "") {
          return true;
        }
      }
    }
    return false;
  }
  const handleExport = (excel_type) => {
    if (regionReportList?.length > 0) {
      let newSheetdata = [];
      regionReportList.map((obj, index) => {
        const newData = {
          SN: parseInt(index) + 1,
          orderType: obj?.orderType || "-",
          regionCode: obj?.regionCode || "-",
          copyDel: obj?.copyDel || "-",
          copyCour: obj?.copyCour || "-",
          count: obj?.count || "-",
          copies: obj?.copies || "-",
        };
        newSheetdata.push(newData);
      });
      let currentDate = new Date();
      let fileName =
        "Region_Excel_Report_" +
        moment(currentDate).format("DD-MM-YYYY") +
        "." +
        excel_type;

      const headings = [
        [
          "SN",
          "Order Type",
          "Region Code",
          "Copy_Del",
          "Copy_Cour",
          "Count",
          "Copies",
        ],
      ];
      const wb = utils.book_new();
      const ws = utils.json_to_sheet([]);
      utils.sheet_add_aoa(ws, headings);
      utils.sheet_add_json(ws, newSheetdata, {
        origin: "A2",
        skipHeader: true,
      });
      utils.book_append_sheet(wb, ws, "Report");
      writeFile(wb, fileName);
    } else {
      toast.error("Data Not Found!");
    }
  };
  const handleFilterChange = (event) => {
    const { name, value } = event.target;
    setFilterRegion({ ...filterRegion, [name]: value });
  };
  const handleSelectFilterChange = (selectedOption, name) => {
    console.log("selectedOption=>", selectedOption)
    const { label, value } = selectedOption || {};
    setFilterRegion({ ...filterRegion, [name]: selectedOption });
  };
  const onFilter = () => {
    if (checkObjectValues(filterRegion)) {
      setIsSearched(true)
      filterRegion.filterFlag = true;
      console.log("filterRegion=>", filterRegion)
      getRegionReport(3);
    } else {
      toast.error("Please Select Alteast One Filter!");
    }
  }
  const getMagazines = () => {
    MasterServices.getAllMagazine(1).then((res) => {
      const { data, status } = res.data
      if (status) {
        const newArray = data?.data?.map((ele) => ({
          ...ele,
          label: ele.mm_name,
          value: ele.mm_id,
        }))
        setGetAllMagazine(newArray);
      }
    }).catch((err) => {
      console.log(err)
    })
  }
  const getMagazineIssues = (e) => {
    MasterServices.getAllMagazineIssues(e.value).then((res) => {
      const { data, status } = res.data;
      if (status) {
        const newArray = data?.data?.map((ele) => ({
          value: ele.mi_id,
          label: ele.mi_name
        }))
        setGetAllMagazinesIssue(newArray)
      }
    }).catch((error) => {
      console.log(error)
    });
  }
  const onFilterClear = () => {
    setIsSearched(false);
    setRegionReportList([]);
    filterRegion.filterFlag = false;
    setFilterRegion(filterInitialValues);
    // getAllProductDispatchList(3);
  };
  const getRegionReport = () => {
    setIsLoading(true)
    let payload = {
      status_code: 3,
      filterRegion: filterRegion,
    }
    ReportServices.getAllRegionWiseReport(payload).then((res) => {
      const { data, status } = res;
      if (status) {
        if (data?.data?.length > 0) {
          setRegionReportList(data?.data);
        } else {
          setRegionReportList([]);
        }
      }
      setIsLoading(false)
    }).catch((error) => {
      console.log(error)

    });
  }

  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <Breadcrumb pages={pages} />
      <div className="">
        <h1 className="text-xl font-semibold text-gray-900">Region Report</h1>
      </div>

      {filterFlag && (
        <div className="px-2 py-2 pb-5 mb-10 sm:px-4 rounded-md bg-white shadow-lg ring-1 ring-white ring-opacity-5 focus:outline-none mb-2">
          <button
            type="button"
            className="text-red-500 absolute right-20 hover:text-red-700 font-bold py-1 px-1 rounded-full border border-red-500 hidden"
            onClick={() => { setFilterFlag(false); onFilterClear(); }}
          >
            X
          </button>
          <div className="space-y-6 sm:space-y-5">
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-3 mt-5">
              <div className="sm:col-span-1 lg:col-span-1">
                <label htmlFor="filterMagazineId" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                  Magazine
                </label>
                <Select
                  name="filterMagazineId"
                  id="filterMagazineId"
                  placeholder="Select Magazine *"
                  onChange={(e) => { console.log("PS=>", e); getMagazineIssues(e); handleSelectFilterChange(e, 'filterMagazineId') }}
                  value={filterRegion.filterMagazineId}
                  options={getAllMagazine}
                  components={animatedComponents}
                  className="basic-multi-select block w-full max-w-lg shadow-sm focus:border-[1px] focus:border-red-500 sm:max-w-xs sm:text-sm"
                  classNamePrefix="select"
                  menuPortalTarget={document.body}
                  styles={{
                    menuPortal: (base) => ({
                      ...base,
                      zIndex: 9999,
                    }),
                  }}
                />
              </div>
              <div className="sm:col-span-1 lg:col-span-1">
                <label htmlFor="filterMagazineIssueId" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                  Magazine Issues
                </label>
                <Select
                  name="filterMagazineIssueId"
                  id="filterMagazineIssueId"
                  placeholder="Select Magazine Issues *"
                  onChange={(e) => { handleSelectFilterChange(e, 'filterMagazineIssueId') }}
                  value={filterRegion.filterMagazineIssueId}
                  options={getAllMagazinesIssue}
                  components={animatedComponents}
                  className="basic-multi-select block w-full max-w-lg shadow-sm focus:border-[1px] focus:border-red-500 sm:max-w-xs sm:text-sm"
                  menuPortalTarget={document.body}
                  styles={{
                    menuPortal: (base) => ({
                      ...base,
                      zIndex: 9999,
                    }),
                  }}
                />
              </div>

              <div className="sm:col-span-1 lg:col-span-1 mt-6">
                <button
                  type="button"
                  className="inline-flex items-center justify-center rounded-md border border-transparent bg-gradient-to-b from-[#872341] to-[#BE3144] px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-[#872341] focus:outline-none focus:ring-2 focus:ring-[#872341] focus:ring-offset-2"
                  onClick={onFilter}
                >
                  Search
                </button>
                <button
                  type="button"
                  className="inline-flex items-center justify-center ml-2 rounded-md border border-transparent bg-gradient-to-b from-[#872341] to-[#BE3144] px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-[#872341] focus:outline-none focus:ring-2 focus:ring-[#872341] focus:ring-offset-2"
                  onClick={onFilterClear}
                >
                  Clear
                </button>
              </div>
            </div>

          </div>
        </div>
      )}

      <div className="flex justify-end">
        <div className="mt-4 flex">
          <div className="flex items-center space-x-2 hidden">
            <div className="relative">
              <button
                onClick={() => {
                  setFilterFlag(filterFlag ? false : true);
                }}
                className="relative z-0 inline-flex text-sm rounded-md shadow-sm focus:ring-accent-500 focus:border-accent-500 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 mr-2">
                <span className="relative inline-flex items-center px-3 py-3 space-x-2 text-sm font-medium text-gray-600 bg-white border border-gray-300 rounded-md sm:py-2">
                  <div>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="w-3 h-3"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      strokeWidth={2}
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M3 4a1 1 0 011-1h16a1 1 0 011 1v2.586a1 1 0 01-.293.707l-6.414 6.414a1 1 0 00-.293.707V17l-4 4v-6.586a1 1 0 00-.293-.707L3.293 7.293A1 1 0 013 6.586V4z"
                      />
                    </svg>
                  </div>
                  <div className="hidden sm:block">Filters {filterFlag ? 'On' : 'Off'}</div>
                </span>
              </button>
            </div>
          </div>
          {/* {allowedActions.filter((data) => data.permission_id == 78)?.length > 0 ? ( */}
            <Link
            to={"#"}
            onClick={() => handleExport("xlsx")}
            type="button"
            className="inline-flex items-center justify-center rounded-md border border-transparent bg-gradient-to-b from-[#872341] to-[#BE3144] px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-[#872341] focus:outline-none focus:ring-2 focus:ring-[#872341] focus:ring-offset-2"
          >
            Download Excel
          </Link>
          {/* ) : null} */}
        </div>
      </div>

      {isLoading ? (
        <FallingLinesLoader />
      ) : (
        <Table
          columns={RegionReport_columns({ allowedActions })}
          data={regionReportList ? regionReportList : []}
          is_toggle={false}
        />
      )}
    </div>
  )
}

export default RegionReport